<template>
  <div class="typewriter">
    <span ref="text"></span>
    <span class="cursor"></span>
  </div>
</template>

<script>
export default {
  name: 'TypeWriter',
  props: {
    interval: { type: Number, default: 250 }
  },
  created () {
    this.$on('typewrite', function () {
      this.typewriter()
    })
  },
  methods: {
    typewriter () {
      var $ele = this.$refs.text
      var str = this.$slots.default[0].text
      var progress = 0
      $ele.innerHTML = ''
      let interval = this.interval
      let skipCount = 0;
      setTimeout(function(){
        setInterval(function() {
          if(progress >= str.length || skipCount>0){
            skipCount--;
            return;
          }
          var current = str.substr(progress, 1)
          if (current === '<') {
            progress = str.indexOf('>', progress) + 1
          } else {
            if(current==' ') skipCount = 4;
            progress++
          }
          $ele.innerHTML =str.substring(0, progress)
        }, interval)
      },1000)
      
    }
  }
}
</script>

<style scoped>
  .cursor{
    width: 2px;
    height: 1em;
    display: inline-block;
    background: var(--color-accent);
    margin-left: 8px;
    animation: blink 0.5s ease infinite alternate;
  }

  .typewriter{
    display: flex;
  }

  @keyframes blink {
    from{
      opacity: 0;
    }
    to{
      opacity: 1;
    }
  }
</style>