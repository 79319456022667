<template>
  <div style="--color-primary: #070c1d; --color-primary-dark: var(--color-primary); background: var(--color-primary); min-height: 100vh;">
    <navbar navselected="community" />
    <motion-canvas />
    <div class="onboarding-inner">
        <div class="onboarding-content">
            <span style="font-weight: 700; font-size: 20px; color: var(--color-accent);">CSI SPIT'S</span>
            <span class="onboarding-title">COMMUNITY</span>
            <span class="onboarding-title" style="color: var(--color-accent);">OF CODERS</span>
            <span style="font-weight: 500; font-size: 18px; margin-top: 16px; text-align: center">
                <type-writer ref="tw" interval="200">
                    By Seniors, For Juniors
                </type-writer>
            </span>
            <big-button href="#join-now" style="margin-top: 32px" bgcolor="var(--color-accent)" textcolor="var(--color-primary)">Join Now</big-button>
            <div style="height: 64px;"></div>
        </div>

        <img class="onboarding-image" src="./../assets/community_logo.png"/>
    </div>
    <section class="subsection mob-center" style="background: var(--color-accent); color: var(--color-primary)">
            <div class="qtitle">
                <div style="padding: 8px 0px;">
                    Learn.<br>
                    Collaborate.<br>
                    Create.
                </div>
            </div>
            <br>
            <div style="margin-top: 48px; font-weight: 500; max-width: 640px; font-size: 16px; line-height: 24px; display: inline-block;">
                We are a family of aspiring young coders and this is our lobby. It is evident that knowledge increases by sharing and ideas are refined and reinforced by discussing them. Hence, Community of Coders was formed.
                <br>
                <br>
                This page is formed by CSI SPIT, with the  best parts being the openly extending network, here members can interact with other passionate coders, share their knowledge, and grow together as a community, and we're sure with yours support by our side, we'll surely fulfill this purpose!
            </div>
        </section>

        <section class="subsection mob-center" style="color: var(--color-accent); background: var(--color-primary)">
            <div class="qtitle invert">
                    Why Us?
            </div>
            <br>
            <div style="margin-top: 48px; font-weight: 500; max-width: 960px; font-size: 16px; line-height: 28px; display: inline-block; color: rgba(255,255,255,0.8)">
                We at Community of Coders don't just plan on uniting all passionate coders. Our aim is also to conduct events and activities catered to provide great practice for the  experienced and to morph the beginners into pros. And when it comes to events, there's no better than CSI SPIT.
                <br>
                <br>
                CSI conducts several workshops throughout the year for aspiring coders along with competitive hackathons for Beginners as well as pros to develop that coding confidence
            </div>
            
            <br>
            <div style="margin-top: 96px;" class="qtitle invert">
                Our Goal
            </div>
            <br>
            <div style="margin-top: 48px; font-weight: 500; max-width: 960px; font-size: 16px; line-height: 28px; display: inline-block; color: rgba(255,255,255,0.8)">
                To provide a platform for all passionate coders to gather and share their ideals to ensure an environment of mutual growth.
            </div>

            <br>
            <div style="margin-top: 96px;" class="qtitle invert">
                Do I need to be a pro?
            </div>
            <br>
            <div style="margin-top: 48px; font-weight: 500; max-width: 960px; font-size: 16px; line-height: 28px; display: inline-block; color: rgba(255,255,255,0.8)">
                Absolutely not!
                <br>
                <br>
                Even if you are a beginner having no experience in coding, Community of Coders has got you covered.
                <br>
                With the community we provide a beginner will be flowering into an experienced coder in no time.
            </div>
        </section>

        <section id="join-now" class="subsection mob-center" style="color: var(--color-primary); background: var(--color-accent); text-align: center; font-weight: 500;">
            <div class="qtitle noline">
                <div style="padding: 8px 0px;">
                    Let's Get Started!
                </div>
            </div>

            <p>
                Come join our community Discord Server or WhatsApp group and let's start building together!
            </p>
            <br>
            <div>
                <big-button href="https://discord.gg/VcAkm3JqVM" target="_blank" style="margin-bottom: 32px">
                    <span><i style="margin-right: 16px;" class="fab fa-discord"></i>Join Discord Server</span>
                </big-button>
            </div>
        </section>
        <m-footer></m-footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MotionCanvas from "@/components/MotionCanvas.vue";
import BigButton from "@/components/BigButton.vue";
import TypeWriter from "@/components/TypeWriter.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Hackathon",
  components: {
    Navbar,
    MotionCanvas,
    BigButton,
    MFooter: Footer,
    TypeWriter
  },
  data(){
      return {
      }
  },
  mounted: function(){
      this.$refs.tw.$emit('typewrite')
  }
};
</script>

<style scoped>
.onboarding-inner{
    width: 100%;
    display: flex;
    flex-direction: row;
    color: #FFF;
    align-items: center;
    padding: 0px 180px;
    transition: all 0.25s;
    min-height: calc(100vh - 96px);
}
.onboarding-content{
    max-width: 480px;
    transition: all 0.25s;
    
}
.onboarding-content span{
    display: block;
}

.onboarding-title{
    font-weight: 800;
    font-size: 54px; 
}

.onboarding-image{
    width: 100%;
    max-width: 360px;
    height: auto;
    margin-left: auto;
}

#mcanvas{
    width: 100%;
    height: 100%;
    display: block;
    position: fixed; 
    top: 0;
    z-index: -9000;
    background: var(--color-primary);
}


.subsection{
    width: 100%;
    padding: 84px 180px;
    transition: all 0.25s;
}

.qtitle{
    font-size: 36px;
    font-weight: 800;
    display: inline-flex;
    flex-direction: row;
}

.qtitle::before{
    content: ' ';
    width: 6px;
    height: auto;
    display: inline-block;
    background: var(--color-primary);
    margin-right: 32px;
}

.qtitle.noline::before{
    display: none;
}

.qtitle.invert::before{
    background: var(--color-accent);
    width: 4px;
}

.logo{
    width: 42px;
    margin-bottom: 64px;
}

@media screen and (max-width: 1280px) {
    .subsection{
        padding: 72px 64px;
    }

    .onboarding-inner{
        padding: 0px 96px;
        align-items: center;
    }

    .onboarding-image{
        
    }
}

@media screen and (max-width: 840px) {
    .onboarding-inner{
        padding: 0px 32px;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .onboarding-content{
        text-align: center;
        align-items: center;
    }

    .onboarding-title{
        font-size: 48px;
    }

    .onboarding-image{
        margin-left: 0;
        height: 128px;
        width: 128px;
    }

    .subsection{
        padding: 48px 32px;
    }

    .subsection.mob-center{
        text-align: center;
    }

    .qtitle{
        font-size: 24px;
    }

    .typewriter{
      justify-content: center;
    }
}

@media screen and (max-width: 640px) {
    .onboarding-title{
        font-size: 42px;
    }

    .qtitle::before{
        display: none;
    }
}

</style>
