<template>
  <div>
    <navbar navselected="team" />
    <motion-canvas />
    <div class="container" style="position:relative; padding-top: 64px; display: flex; flex-direction: column; padding-bottom: 64px;">
        <span style="color: var(--color-accent); font-weight: 700; font-size: 14px; letter-spacing: 0.32em;">OUR AMAZING</span>
        <span style="font-weight: 700; font-size: 42px; letter-spacing: 0.225em; margin-top: 8px; margin-bottom: 16px">TEAM</span>
        <div style="margin-top: 24px" v-for="(comm,i) in teams" v-bind:key="i">
          <span style="font-weight: 600; font-size: 20px; letter-spacing: 0.065em; margin-top: 32px;">
            <span style="color: var(--color-accent)">{{comm.type}}</span>
            {{comm.year_start}}
            <span style="color: var(--color-accent)">-</span>
            {{comm.year_end}}
          </span>
          <div class="team-holder">
            <team-card v-for="(item,index) in comm.members" v-bind:key="index" :member="item" data-aos="fade-up"></team-card>
          </div>
        </div>   
    </div>
    <m-footer></m-footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MotionCanvas from "@/components/MotionCanvas.vue";
import TeamCard from "@/components/TeamCard.vue";
import Footer from "@/components/Footer.vue";
import teamData from "@/assets/content/team.json";


export default {
  name: "Team",
  components: {
    Navbar,
    MotionCanvas,
    TeamCard,
    MFooter: Footer
  },
  data() {
    return {
      teams: teamData.teams
    }
  },
};
</script>

<style scoped>

  .team-holder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 24px;
    padding-bottom: 16px;
  }

  

  @media screen and (max-width: 1280px) {
    .container{
      padding: 96px;
    }
  }

  @media screen and (max-width: 840px) {
    .container{
      padding: 32px;
    }

    .header-holder{
      align-items: center;
      text-align: center;
    }
  }
</style>
