<template>
  <div style="--color-accent: #5ce7ec; --color-primary-dark: #121617; --color-primary: #0D272A; min-height: 100vh; font-family: Inconsolata; --nav-filter: invert(32%) sepia(42%) saturate(5151%) hue-rotate(451deg) brightness(87%) contrast(63%);">
    <navbar navselected="hackathon" />
    <waves-motion />
    <div id="bottom-popup" class="popup">
      <span style="margin-right: auto;">TEAMS SHORTLISTED FOR THE HACKATHON HAVE BEEN EMAILED</span>
      <!-- <a target="_BLANK"
        href="https://unstop.com/p/spit-hackathon-2023-sardar-patel-institute-of-technology-spit-mumbai-567843">Let's Go!</a> -->
    </div> 
    <div class="container hack-soon">
      <div style="display: flex; flex-direction: column;">
          <span class="hack-text-sup">S.P.I.T.</span>
          <span class="hack-text">HACKATHON</span>
          <span class="hack-text-sub">2023</span>
      </div>

      <!-- <span style="color: var(--color-accent); font-size: 24px; letter-spacing: 0.2em; margin-top: 64px; text-align: center;">
        <type-writer ref="tw" interval="100">
          REGISTRATIONS OPEN SOON
        </type-writer>
      </span> -->

      <big-button 
        target="_BLANK"
        href="https://unstop.com/hackathon/spit-hackathon-2023-sardar-patel-institute-of-technology-spit-mumbai-567843" style="margin-top: 32px; font-size: 16px" bgcolor="var(--color-accent)" textcolor="var(--color-primary-dark)"
      >VIEW ON UNSTOP</big-button>

      <div style="height: 120px"></div>
    </div>
    <div class="container" style="display: flex; flex-direction: column">
        <div class="hack-desc" style="color: rgba(255,255,255,0.75);">
          <div style="font-size: 18px; line-height: 20px; display: block; max-width: 640px; line-height: initial;" data-aos="fade-right">
            <div style="display: flex; flex-direction: column; margin-bottom: 32px">
              <span style="font-size: 20px; color: var(--color-accent);">Announcing</span>
              <span style="font-size: 36px; color: #FFF;">SPIT Hackathon 2023</span>
            </div>
            Following tradition, CSI-SPIT is conducting the annual 24-hour "SPIT Hackathon" in February 2023.<br>
            This year, we are going back to organizing the hackathon completely in Offline Mode.<br>
            With a great team and skilled panel of judges, this hackathon will be an ideal platform to put student's knowledge to use and network with peers.<br><br>
            
            <div class="hack-details">
              <i class="fa fa-calendar" /><span>Date</span> : 4th &amp; 5th February, 2023<br><br>
              <i class="fa fa-group" /><span>Team Size</span> : 2-4 Members<br><br>
              <i class="fa fa-rocket" /><span>Domains</span> : Web/App Dev, AI/ML, AR/VR, Blockchain, FOSS<br><br>
              <i class="fa fa-map-pin" /><span>Location</span> : 
              <a>Sardar Patel Institute of Technology, Mumbai</a>
              <br>
            </div>
          </div>

          <div class="hex-holder" data-aos="fade-left">
            <div class="hack-hex-outer">
              <div class="hack-hex"><i class="fa fa-mobile"></i></div>
              <span>Mobile Dev</span>
            </div>
            <div class="hack-hex-outer">
              <div class="hack-hex"><i class="fa fa-vr-cardboard"></i></div>
              <span>AR/VR</span>
            </div>
            <div class="hack-hex-outer">
              <div class="hack-hex"><i class="fa fa-rocket"></i></div>
              <span>AI / ML</span>
            </div>
            <div class="hack-hex-outer">
              <div class="hack-hex"><i class="fa fa-globe"></i></div>
              <span>Web Dev</span>
            </div>
            <div class="hack-hex-outer">
              <div class="hack-hex"><i class="fa fa-code"></i></div>
              <span>Open-Source</span>
            </div>
            <div class="hack-hex-outer">
              <div class="hack-hex"><i class="fa fa-bitcoin"></i></div>
              <span>Blockchain</span>
            </div>
          </div>
        </div>
      </div>

      <div class="container" style="display: flex; flex-direction: column">
        <span style="font-size: 36px; color: #FFF; text-align: center; margin-top: 32px;">Prizes</span>
        <span class="subdiv" style="margin: 32px auto 48px auto"></span>
        <div style="margin-top: 24px; display: flex; flex-wrap: wrap;" data-aos="fade-up">
          <div class="prize-window">
            <div class="prize-window-title">1st Place</div>
            <div class="prize-window-content">
              <span class="prize-amount">INR 35,000/-</span>
              <span style="color: var(--color-accent); margin-top: 16px">With Internship Opportunities, Exciting Goodies &amp; Lots More!</span>
            </div>
          </div>
          <div class="prize-window">
            <div class="prize-window-title">2nd Place</div>
            <div class="prize-window-content">
              <span class="prize-amount">INR 25,000/-</span>
              <span style="color: var(--color-accent); margin-top: 16px">With Internship Opportunities, Exciting Goodies &amp; Lots More!</span>
            </div>
          </div>
          <div class="prize-window">
            <div class="prize-window-title">3rd Place</div>
            <div class="prize-window-content">
              <span class="prize-amount">INR 15,000/-</span>
              <span style="color: var(--color-accent); margin-top: 16px">With Internship Opportunities, Exciting Goodies &amp; Lots More!</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container" style="display: flex; flex-direction: column; width: 100%; align-items: center; margin-top: 64px; margin-bottom: 64px;">
        <span style="font-size: 36px; color: #FFF; text-align: center;">Schedule</span>
        <span class="subdiv" style="margin: 32px auto 48px auto"></span>
        <div class="tl-holder">
          <div class="tl-title">Round 1</div>

          <div class="tl-item tl-left">
            <div class="tl-content" data-aos="fade-right">
              <div class="tl-content-title">
                <span>23rd December, 2022</span>
              </div>
              <div class="tl-content-data">
                Registrations Start
              </div>
            </div>
          </div>

          <div class="tl-item tl-right">
            <div class="tl-content" data-aos="fade-left">
              <div class="tl-content-title">
                <span>24th December, 2022</span>
              </div>
              <div class="tl-content-data">
                Round 1 Submissions Open
              </div>
            </div>
          </div>

          <div class="tl-item tl-left">
            <div class="tl-content" data-aos="fade-right">
              <div class="tl-content-title">
                <span>17th January, 2023</span>
                <span class="tl-content-subtitle">11:59 PM</span>
              </div>
              <div class="tl-content-data">
                Registrations End
              </div>
            </div>
          </div>

          <div class="tl-item tl-right">
            <div class="tl-content" data-aos="fade-left">
              <div class="tl-content-title">
                <span>18th January, 2023</span>
                <span class="tl-content-subtitle">11:59 PM</span>
              </div>
              <div class="tl-content-data">
                Round 1 Submissions End
              </div>
            </div>
          </div>

          <div class="tl-item tl-left">
            <div class="tl-content" data-aos="fade-right">
              <div class="tl-content-title">
                <span>21st January, 2023</span>
              </div>
              <div class="tl-content-data">
                Shortlisted Teams Announcement
              </div>
            </div>
          </div>

          <div class="tl-title" style="margin-top: 32px;">Round 2</div>

          <div class="tl-item tl-right">
            <div class="tl-content" data-aos="fade-left">
              <div class="tl-content-title">
                <span>3rd February, 2023</span>
              </div>
              <div class="tl-content-data">
                Problem Statement Preference Form Release
              </div>
            </div>
          </div>

          <div class="tl-item tl-left">
            <div class="tl-content" data-aos="fade-right">
              <div class="tl-content-title">
                <span>4th - 5th February, 2023</span>
              </div>
              <div class="tl-content-data">
                Grand Finale at Sardar Patel Institute of Technology
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-shape-divider-bottom-1635684006">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
        </svg>
    </div>
    <div class="container" style="background: var(--color-accent); ">
      <div class="sponsor-section">
        <span style="font-size: 36px; font-weight: 700; margin-bottom: 24px">Sponsors 2023</span>
        <span class="subdiv" style="background: var(--color-primary-dark);"></span>

        <span class="sponsor-subtitle">Community Partners</span>
        <div class="sponsor-wrap">
          <div class="sponsor-subsection">
            <a href="https://www.echo3d.co/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/echo3D.png" /></a>
          </div>

          <div class="sponsor-subsection">
            <a href="https://digitalocean.com" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/do.png" /></a>
          </div>
        </div>

        <div class="half-section">
          <div class="half-section-half sponsor-subsection">
            <span class="sponsor-subtitle">Sticker Partner</span>
            <a href="https://mule.to/p3dq" target="_blank" class="sponsor-img" data-aos="fade-up"><img style="background: #4e2817" src="images/sponsors/StickerMule.svg" /></a>
          </div>
          <div class="half-section-half sponsor-subsection">
            <span class="sponsor-subtitle">FOSS Partner</span>
            <a href="https://fossunited.org/" target="_blank" class="sponsor-img" data-aos="fade-up">
              <img style="background: #1a1a1a; padding: 32px 96px" src="images/sponsors/foss.png" />
            </a>
          </div>
        </div>
        
      </div>

        <div class="sponsor-section">
          <span style="font-size: 36px; font-weight: 700; margin-bottom: 24px">Sponsors 2022</span>
          <span class="subdiv" style="background: var(--color-primary-dark);"></span>
          <div class="sponsor-subsection">
            <span class="sponsor-subtitle">Title Sponsor</span>
            <a href="https://www.emkayglobal.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/emkay.png" /></a>
          </div>
          <div class="half-section">
            <div class="half-section-half sponsor-subsection">
              <span class="sponsor-subtitle">Certification Partner</span>
              <a href="https://givemycertificate.com/" target="_blank" class="sponsor-img" data-aos="fade-up">
                <img alt="Give My Certificate" style="height: 128px; object-fit: contain; object-position: center;" src="images/sponsors/gmc.png" />
              </a>
            </div>
            <div class="half-section-half sponsor-subsection">
              <span class="sponsor-subtitle">Study Abroad Partner</span>
              <a href="https://yocket.in/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/yocket-2021.png" /></a>
            </div>
          </div>
          <span class="sponsor-subtitle">Community Partners</span>
          <div class="sponsor-wrap">
            <div class="sponsor-subsection">
              <a href="https://codingblocks.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/codingblock.png" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://hoverrobotix.com/" target="_blank" class="sponsor-img" data-aos="fade-up">
                <img style="height: 160px; object-fit: contain; object-position: center;" src="images/sponsors/HoverRobotix.png" />
              </a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://www.echo3d.co/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/echo3D.png" /></a>
            </div>
          </div>
          <div class="sponsor-subsection">
            <span class="sponsor-subtitle">Associate Partner</span>
            <a href="https://prideeducare.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img style="background: #0a2f57;" src="images/sponsors/pride_educare.png" /></a>
          </div>
        </div>

        <div class="sponsor-section sponsor-old">
          <span style="font-size: 36px; font-weight: 700; margin-bottom: 24px">Sponsors 2021</span>
          <span class="subdiv" style="background: var(--color-primary-dark);"></span>
          <div class="sponsor-subsection">
            <span class="sponsor-subtitle">Title Sponsor</span>
            <a href="https://www.neebal.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/neebal-logo-2021.png" /></a>
          </div>
          <div class="sponsor-subsection">
            <span class="sponsor-subtitle">Co-Title Sponsor</span>
            <a href="https://devfolio.co/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/devbig.png" /></a>
          </div>
          <div class="half-section">
            <div class="half-section-half sponsor-subsection">
              <span class="sponsor-subtitle">Certification Partner</span>
              <a href="https://sybgen.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/sybgen_2021.jpg" /></a>
            </div>
            <div class="half-section-half sponsor-subsection">
              <span class="sponsor-subtitle">Committee Partner</span>
              <a href="https://yocket.in/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/yocket-2021.png" /></a>
            </div>
          </div>
          <div class="sponsor-wrap">
            <div class="sponsor-subsection">
              <a href="https://www.portis.io/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/portis_2021.png" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://matic.network/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/matic.svg" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://tezos.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/tezos_2021.png" /></a>
            </div>
          </div>
          <div class="sponsor-subsection">
            <span class="sponsor-subtitle">Community Partner</span>
            <a href="https://fluttervalsad.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img class="im-cover" style="height: 144px" src="images/sponsors/Flutter_Valsad_Logo.png" /></a>
          </div>
        </div>

        <div class="sponsor-section sponsor-old">
          <span style="font-size: 36px; font-weight: 700; margin-bottom: 24px">Sponsors 2020</span>
          <span class="subdiv" style="background: var(--color-primary-dark);"></span>
          <div class="sponsor-subsection">
            <a href="https://devfolio.co/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/devbig.png" /></a>
          </div>
          <div class="sponsor-subsection">
            <a href="https://sequretek.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/sqt.png" /></a>
          </div>
          <div class="sponsor-wrap">
            <div class="sponsor-subsection">
              <a href="https://www.lntinfotech.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/lti.png" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="http://mintoak.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/mintoak.svg" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://www.sptbi.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/sptbi.png" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://gocrowdera.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/crowdera2.png" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://codingblocks.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/codingblock.png" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://matic.network/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/matic.svg" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://zubi.io/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/zubi.jpg" /></a>
            </div>
            <div class="sponsor-subsection">
              <a href="https://www.wolfram.com/" target="_blank" class="sponsor-img" data-aos="fade-up"><img src="images/sponsors/wolfram.jpg" /></a>
            </div>
          </div>
        </div>
    </div>

    <m-footer></m-footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import BigButton from "@/components/BigButton.vue";
import { useSound } from '@vueuse/sound'
import buttonSfx from '@/assets/hover.mp3'
import WavesMotion from '../components/WavesMotion.vue';

export default {
  name: "Hackathon",
  components: {
    Navbar,
    WavesMotion,
    MFooter: Footer,
    BigButton
  },
  data(){
      return {
      }
  },
  mounted: function(){
    window.addEventListener('scroll', ()=>{
      if(document.body.scrollHeight - window.scrollY < window.outerHeight + 128){
        document.getElementById('bottom-popup').style.display = 'none';
      } else{
        document.getElementById('bottom-popup').style.display = 'flex';
      }
    })

    this.$refs.tw.$emit('typewrite')
  },
  setup(){
    const { play } = useSound(buttonSfx)
    return {
      playHover: play,
    }
  }
};
</script>

<style>
  .hack-soon{
      display: flex;
      width: 100%;
      height: calc(100vh - 96px);
      align-items: center;
      flex-direction: column;
      padding-top: 0px;
      justify-content: center;
      text-shadow: black 0px 8px 24px;
  }

  .hack-text{
      font-size: 56px;
      font-weight: 800;
      letter-spacing: 0.5em;
  }

  .hack-text-sup{
      font-size: 28px; 
      font-weight: 700; 
      color: var(--color-accent);
  }

  .hack-text-sub{
    font-size: 24px;
    font-weight: 600; 
    color: var(--color-accent); 
    text-align: right; 
    padding-right: 28px;
  }

  .hack-desc{
    display: flex;
    padding-bottom: 64px;
  }

  .hex-holder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: right;
    max-width: 540px;
    margin-left: auto;
  }
  
  .hack-hex-outer{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-accent);
    margin-left: 64px;
    margin-top: 16px;
    transition: all 0.25s;
    cursor: pointer;
  }

  .hack-hex-outer span{
    margin-top: 16px;
  }

  .hack-hex{
    width: 111px; 
    height: 128px; 
    background-repeat: no-repeat; 
    background-image: url(/images/hex.svg);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    transition: all 0.25s;
  }

  .hack-hex-outer:hover{
    color: #FFF;
  }

  .hack-hex-outer:hover .hack-hex {
    filter: brightness(1000);
  }

  .prize-window{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: solid 2px var(--color-accent);
    width: 280px;
    flex-grow: 1;
    margin: 8px;
    transition: all 0.25s ease;
    background: transparent;
    cursor: pointer;
    box-shadow: #c2a0fe40 0px 2px 16px;
  }
  
  .prize-window:hover{
    transform: translateY(-16px) scale(1.125);
    background: var(--color-primary-dark);
    z-index: 2;
  }

  .prize-window-title{
    display: flex;
    background: var(--color-accent);
    width: 100%;
    border-radius: 6px 6px 0px 0px;
    height: 42px;
    font-size: 18px;
    color: var(--color-primary-dark);
    font-weight: 600;
    align-items: center;
    justify-content: center;
    outline: solid 2px var(--color-accent);
  }

  img.prize-window-title{
    padding: 16px;
    height: 64px;
    object-position: center;
    object-fit: contain;
    background: var(--color-primary);
  }

  .prize-window-content{
    display: flex;
    flex-direction: column;
    padding: 36px 24px;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .prize-amount{
    font-size: 32px;
  }

  .im-contain{
    object-fit: cover;
    object-position: center;
  }

  .im-cover{
    object-fit: cover;
    object-position: center;
  }

  .sponsor-wrap{
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .half-section{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .half-section-half{
   flex-grow: 1;
  }

  .tl-holder{
    position: relative;
    max-width: 1200px;
    width: 100%
  }

  .tl-holder::after {
    content: '';
    position: absolute;
    width: 4px;
    background-color: var(--color-accent);
    top: 0;
    left: 50%;
    margin-left: -2px;
    bottom: 96px;
  }

  .tl-item {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
    transition: all 0.25s;
    cursor: pointer;
  }

  .tl-title{
    width: 280px;
    font-size: 24px;
    text-align: center;
    position: relative;
    left: calc(50% - 140px);
    padding: 16px 24px;
    border: 4px solid var(--color-accent);
    background: var(--color-primary-dark);
    border-radius: 16px;
    margin-bottom: 24px;
    box-shadow: #c2a0fe40 0px 2px 16px;
  }

  .tl-item::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: var(--color-primary-dark);
    border: 4px solid var(--color-accent);
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    transition: all 0.25s;
  }

  .tl-item:hover::after{
    transform: scale(1.125);
  }

  .tl-item::before{
    transition: all 0.5s;
  }

  .tl-left {
    left: 0;
  }

  .tl-right {
    left: 50%;
  }

  .tl-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid var(--color-accent);
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent var(--color-accent);
  }

  .tl-left:hover::before{
    transform: translateX(-16px);
  }
  
  .tl-right:hover::before{
    transform: translateX(16px);
  }

  .tl-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid var(--color-accent);
    border-width: 10px 10px 10px 0;
    border-color: transparent var(--color-accent) transparent transparent;
  }

  .tl-right::after {
    left: -16px;
  }

  .tl-content {
    border: solid 4px var(--color-accent);
    position: relative;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: all 0.25s;
    box-shadow: #c2a0fe40 0px 2px 16px;
  }

  .tl-right:hover .tl-content{
    transform: translateX(16px);
  }

  .tl-left:hover .tl-content{
    transform: translateX(-16px);
  }

  .tl-content-title{
    display: flex;
    background: var(--color-accent);
    width: 100%;
    border-radius: 0px;
    height: 42px;
    font-size: 18px;
    color: var(--color-primary-dark);
    font-weight: 600;
    align-items: center;
    justify-content: flex-start;
    outline: solid 2px var(--color-accent);
    padding: 0px 16px;
    display: flex;
  }

  .tl-content-subtitle{
    font-size: 14px;
    text-align: right;
    flex-grow: 1;
  }

  .tl-content-data{
    padding: 16px 24px;
  }

  .custom-shape-divider-bottom-1635684006 {
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1635684006 svg {
      position: relative;
      display: block;
      width: calc(100% + 1.3px);
      height: 24px;
  }

  .custom-shape-divider-bottom-1635684006 .shape-fill {
      fill: var(--color-accent);
  }

  .subdiv{
    display: block; 
    background: var(--color-accent); 
    width: 64px; 
    height: 8px; 
    transform: skewX(45deg); 
    margin-bottom: 32px
  }

  .sponsor-section{
    padding: 64px 0px;
    color: var(--color-primary-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sponsor-section span{
    text-align: center;
  }

  .sponsor-subsection{
    display: flex; 
    flex-direction: column;
    margin-bottom: 32px;
    align-items: center;
    color: var(--color-primary-dark);
    padding: 0px 16px;
  }

  .sponsor-old .sponsor-img{
    height: 128px;
    object-fit: contain;
    object-position: center;
  }

  .sponsor-old .sponsor-img img{
    height: 128px;
    object-fit: contain;
    object-position: center;
  }

  .sponsor-img img{
    width: 100%;
    padding: 24px;
    background: #FFF;
    border-radius: 16px;
    border: solid 4px var(--color-primary-dark);
    transition: transform 0.25s;
  }

  .sponsor-img{
    width: 100%;
    max-width: 360px;
    border-radius: 16px;
  }

  .sponsor-img img:hover{
    transform: scale(1.0625);
  }

  .sponsor-subtitle{
    font-size: 36px; 
    font-weight: 700;
    text-align: center;
    margin-bottom: 24px;
  }

  .popup{
    width: 90%;
    display: flex;
    max-width: 480px;
    background: var(--color-accent);
    color: var(--color-primary-dark);
    position: fixed;
    z-index: 999;
    bottom: 32px;
    right: 36px;
    font-weight: 700;
    border: solid 1px var(--color-primary-dark);
    outline: solid 1px var(--color-accent);
  }

  .popup span{
    padding: 16px;
  }

  .popup a{
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary-dark);
    color: var(--color-accent);
    padding: 16px 24px;
    text-decoration: none;
  }

  .popup a:hover{
    text-decoration: underline;
  }

  .hack-details i {
    width: 24px;
    margin-right: 8px;
    color: var(--color-accent);
  }

  .hack-details span {
    color: var(--color-accent);
    width: 96px;
    display: inline-block;
  }

  @media screen and (max-width: 1180px) {
    .hack-desc{
      flex-direction: column;
      text-align: center;
      align-items: center;
    }

    .hex-holder{
      margin-left: initial;
      max-width: initial;
      justify-content: center;
      margin-top: 32px;
    }

    .hack-hex-outer{
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1080px) {
    .tl-holder::after {
      left: 31px;
      top: 128px;
    }

    .tl-item {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }

    .tl-item::before {
      left: 60px;
      border: medium solid var(--color-accent);
      border-width: 10px 10px 10px 0;
      border-color: transparent var(--color-accent) transparent transparent;
    }

    .tl-left::after, .tl-right::after {
      left: 15px;
    }

    .tl-left:hover::before, .tl-right:hover:before {
      transform: translateX(16px);
    }

    .tl-left:hover .tl-content{
      transform: translateX(16px);
    }

    .tl-right {
      left: 0%;
    }
  }

  @media screen and (max-width: 640px) {
    .hack-text{
        font-size: 42px;
    }

    .tl-content-title{
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: auto;
      padding: 12px 24px;
    }
    
    .tl-content-subtitle{
      flex-grow: 0;
    }
    
    .half-section{
      flex-direction: column;
    }

    .popup{
      bottom: 16px;
      left: 5%;
      right: 5%;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 440px) {
    .hack-text{
        font-size: 28px;
    }

    .hack-text-sup{
        font-size: 22px;
    }

    .hack-text-sub{
        font-size: 18px;
        padding-right: 14px;
    }
  }
</style>
