<template>
  <div>
    <navbar navselected="events" />
    <motion-canvas />
    <event-popup />
    <div class="container" style="position:relative; padding-top: 64px; display: flex; flex-direction: column; padding-bottom: 64px;">
        <span style="color: var(--color-accent); font-weight: 700; font-size: 20px; letter-spacing: 0.32em;" v-if="upcomingEvents.length>0">RECENT</span>
        <span style="font-weight: 700; font-size: 32px; letter-spacing: 0.225em; margin-top: 4px; margin-bottom: 16px;" v-if="upcomingEvents.length>0">EVENTS</span>

        <div class="upcoming-big" v-if="upcomingEvents.length>0" data-aos="fade-right">
          <img class="event-img" :src="upcomingEventsFirst.image" />
          <div class="event-content">
              <span style="font-size: 12px; font-weight: 600; text-transform: uppercase; letter-spacing: 0.15em; margin-bottom: 8px; opacity: 0.8;">
                {{upcomingEventsFirst.date}}
              </span>
              <span style="font-size: 24px; font-weight: 700; text-transform: uppercase; letter-spacing: 0.1em;">
                {{upcomingEventsFirst.title}}
              </span>
              <span style="font-size: 16px; font-weight: 400; text-transform: uppercase; letter-spacing: 0.1em; margin-top: 8px; opacity: 0.8;">
                {{upcomingEventsFirst.subtext}}
              </span>
              <div class="event-summary">
                {{upcomingEventsFirst.description}}
              </div>
              <div style="margin-top: 32px;">
                <big-button target="_blank" v-if="upcomingEventsFirst.actionURL" :href="upcomingEventsFirst.actionURL || '/event/'+upcomingEventsFirst.slug+'/register'">
                  {{upcomingEventsFirst.actionText || "Register Now"}}
                </big-button>
                <big-button v-if="upcomingEventsFirst.hasMore" :href="upcomingEventsFirst.moreURL || '/event/'+upcomingEventsFirst.slug">
                  Learn More
                </big-button>
                <big-button v-if="!upcomingEventsFirst.hasMore" v-on:click.native="bigLearnMore">
                  Learn More
                </big-button>
              </div>
          </div>
        </div>        
        <div class="events-holder">
          <event-card v-for="(event, i) in upcomingEventsExc" v-bind:key="i" :event="event" data-aos="fade-up"></event-card>
        </div>
        <div style="height: 48px"></div>
        <span style="color: var(--color-accent); font-weight: 700; font-size: 20px; letter-spacing: 0.32em;">PAST</span>
        <span style="font-weight: 700; font-size: 32px; letter-spacing: 0.225em; margin-top: 4px; margin-bottom: 16px;">EVENTS</span>
        <div class="events-holder">
          <event-card v-for="(event, i) in pastEvents" v-bind:key="i" :event="event" data-aos="fade-up"></event-card>
        </div>
    </div>
    <m-footer></m-footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MotionCanvas from "@/components/MotionCanvas.vue";
import EventCard from "@/components/EventCard.vue";
import EventPopup from "@/components/EventPopup.vue";
import Footer from "@/components/Footer.vue";
import BigButton from "@/components/BigButton.vue";
import eventData from "@/assets/content/events.json";

export default {
  name: "Events",
  components: {
    Navbar,
    MotionCanvas,
    EventCard,
    MFooter: Footer,
    BigButton,
    EventPopup
  },
  data(){
    return {
      events: eventData.events
    }
  },
  computed: {
    pastEvents: function(){
      return this.events.filter(e=>!e.upcoming)
    },
    upcomingEvents:  function(){
      return this.events.filter(e=>e.upcoming)
    },
    upcomingEventsExc:  function(){
      return this.events.filter(e=>e.upcoming).slice(1)
    },
    upcomingEventsFirst: function(){
      return this.events.filter(e=>e.upcoming)[0]
    }
  },
  methods:{
    bigLearnMore(){
      if(this.upcomingEventsFirst.learnMore) window.location = this.upcomingEventsFirst.learnMore;
      else this.$root.$emit('show-event-popup',this.upcomingEventsFirst)
    }
  }
};
</script>

<style scoped>
  .upcoming-big{
    width: 100%;
    height: 360px;
    background: var(--color-accent);
    margin: 16px 0px;
    display: flex;
    box-shadow: 0px 2.8px 2.2px rgb(165 240 211 / 1%), 0px 6.7px 5.3px rgb(165 240 211 / 1%), 0px 12.5px 10px rgb(165 240 211 / 1%), 0px 22.3px 17.9px rgb(165 240 211 / 1%), 0px 41.8px 33.4px rgb(165 240 211 / 1%), 0px 100px 80px rgb(165 240 211 / 2%);
  }

  .upcoming-big .event-img{
    height: 100%;
    width: 360px;
    object-fit: cover;
    object-position: center;
  }

  .upcoming-big .event-content{
    color: var(--color-primary-dark);
    padding: 32px 42px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  .upcoming-big .event-summary{
    display: inline-block;
    font-size: 14px; 
    font-weight: 500; 
    letter-spacing: 0.1em; 
    margin-top: 20px; 
    overflow: hidden;
    -webkit-line-clamp: 6;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .upcoming-big-btns{
    display: flex;
    flex-direction: row;
  }

  .bigbtn{
    background: var(--color-primary);
    color: var(--color-secondary);
    font-weight: 600;
    font-size: 12px;
    text-decoration: none;
    padding: 16px 64px;
    display: inline-block;
    width: fit-content;
    transition: all 0.25s;
    clip-path: polygon(0% 0%, calc(100% - 32px) 0%, 100% 100%, 32px 100%);
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 8px;
  }

  .bigbtn:hover{
    clip-path: polygon(0px 0%, calc(100% - 40px) 0%, calc(100% - 0px) 100%, 40px 100%);
  }

  .bigbtn:active{
    clip-path: polygon(0px 0%, calc(100% - 48px) 0%, calc(100% - 0px) 100%, 48px 100%);
  }

  .events-holder{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  @media screen and (max-width: 1280px) {
    .upcoming-big{
      flex-direction: column;
      width: 100%;
      height: fit-content;
    }

    .upcoming-big .event-img{
      width: 100%;
      height: 240px;
    }

    .upcoming-big .event-summary{
      max-height: calc(16rem + 4px); 
    }

    .bigbtn{
      width: 100%;
      text-align: center;
      min-width: 240px;
    }
  }

  @media screen and (max-width: 420px) {
    .container{
      padding: 32px;
    }

    .bigbtn{
      width: 100%;
      min-width: 0px;
    }
  }
</style>
