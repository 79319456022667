<template>
  <div class="wm-holder" ref="holder">
  </div>
</template>


<script>
/* eslint-disable no-unused-vars */
import * as THREE from 'three';


var SEPARATION = 100,
        AMOUNTX = 100,
        AMOUNTY = 70;

var container;
var camera, scene, renderer;

var particles, count = 0;

var mouseX = 85,
    mouseY = -342;

var rawX = 85,
    rawY = -342;

var isActive = false;

var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

var material1 = new THREE.ShaderMaterial( {
  uniforms: {
    color: { value: new THREE.Color( 0x5ce7ec ) },
    alpha: { value: 0.25 }
  },
  vertexShader: document.getElementById( 'vertexshader' ).textContent,
  fragmentShader: document.getElementById( 'fragmentshader' ).textContent
} );

var material2 = new THREE.ShaderMaterial( {
  uniforms: {
    color: { value: new THREE.Color( 0x5ce7ec ) },
    alpha: { value: 0.1 }
  },
  vertexShader: document.getElementById( 'vertexshader' ).textContent,
  fragmentShader: document.getElementById( 'fragmentshader' ).textContent
} );

export default {
  name: "WavesMotion",
  methods: {
    init(){
      container = this.$refs['holder'];

      camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 100000 );
      camera.position.z = 1000;

      scene = new THREE.Scene();

      //

      const numParticles = AMOUNTX * AMOUNTY;

      const positions = new Float32Array( numParticles * 3 );
      const scales = new Float32Array( numParticles );

      let i = 0, j = 0;

      for ( let ix = 0; ix < AMOUNTX; ix ++ ) {

        for ( let iy = 0; iy < AMOUNTY; iy ++ ) {

          positions[ i ] = ix * SEPARATION - ( ( AMOUNTX * SEPARATION ) / 2 ); // x
          positions[ i + 1 ] = 0; // y
          positions[ i + 2 ] = iy * SEPARATION - ( ( AMOUNTY * SEPARATION ) / 2 ); // z

          scales[ j ] = 1;

          i += 3;
          j ++;

        }

      }

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute( 'position', new THREE.BufferAttribute( positions, 3 ) );
      geometry.setAttribute( 'scale', new THREE.BufferAttribute( scales, 1 ) );

      const material = material1;

      //

      particles = new THREE.Points( geometry, material );
      scene.add( particles );

      //

      renderer = new THREE.WebGLRenderer( { antialias: true, alpha: true} );
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( window.innerWidth, window.innerHeight );
      let gl = renderer.getContext();
      gl.blendFunc(gl.SRC_ALPHA,gl.ONE_MINUS_SRC_ALPHA)
      gl.enable(gl.BLEND);
      container.appendChild( renderer.domElement );

      document.addEventListener('mousemove', (event)=>{
        rawX = event.clientX;
        rawY = event.clientY;
        mouseX = rawX - windowHalfX-Math.min(window.scrollY,500);
        mouseY = rawY - windowHalfY-Math.min(window.scrollY,500);
      }, false);
      /*document.addEventListener('touchstart', (event)=>{
        if (event.touches.length === 1) {
 
            event.preventDefault();
 
            mouseX = event.touches[0].pageX - windowHalfX;
            mouseY = event.touches[0].pageY - windowHalfY;
 
        }
      }, false);
      document.addEventListener('touchmove', (event)=>{
        if (event.touches.length === 1) {
 
            event.preventDefault();
 
            mouseX = event.touches[0].pageX - windowHalfX;
            mouseY = event.touches[0].pageY - windowHalfY;
 
        }
      }, false);*/

      window.addEventListener('resize', (event)=>{
        windowHalfX = window.innerWidth / 2;
        windowHalfY = window.innerHeight / 2;
        if(camera==null) return;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
 
        renderer.setSize(window.innerWidth, window.innerHeight);
      }, false);

      window.addEventListener('scroll',()=>{
        if(particles==null) return;
        let max = 0.5;
        let min = 0.2;
        particles.material.uniforms.alpha.value = window.scrollY<250 ? max : max-(Math.min((window.scrollY-250)/300,1)*(max-min));
        document.dispatchEvent(new MouseEvent('mousemove',{
          clientX: rawX,
          clientY: rawY,
        }))
      })

      isActive = true;
    },
    animate(){
      if(!isActive) return;
      requestAnimationFrame(this.animate);
 
      this.render();
    },
    render(){
      camera.position.x += ( mouseX - camera.position.x) * .05;
      camera.position.y += ( - mouseY - camera.position.y) * .05;
      camera.lookAt( scene.position );

      const positions = particles.geometry.attributes.position.array;
      const scales = particles.geometry.attributes.scale.array;

      let i = 0, j = 0;
      let scaleFactor = window.innerWidth > 800 ? 10 : 20;

      for ( let ix = 0; ix < AMOUNTX; ix ++ ) {

        for ( let iy = 0; iy < AMOUNTY; iy ++ ) {

          positions[ i + 1 ] = ( Math.sin( ( ix + count ) * 0.3 ) * 50 ) +
                  ( Math.sin( ( iy + count ) * 0.5 ) * 50 );

          scales[ j ] = ( Math.sin( ( ix + count ) * 0.3 ) + 1 ) * scaleFactor +
                  ( Math.sin( ( iy + count ) * 0.5 ) + 1 ) * scaleFactor;

          i += 3;
          j ++;

        }

      }

      particles.geometry.attributes.position.needsUpdate = true;
      particles.geometry.attributes.scale.needsUpdate = true;

      renderer.render( scene, camera );

      count += 0.1;
    }
  },
  mounted() {
      this.init();
      this.animate();
  },
  destroyed() {
    isActive = false;
    container = null;
    camera = null;
    scene = null;
    renderer = null;
    particles = null;
    count = null;
  },
};
</script>

<style>
    .wm-holder{
      z-index: 0;
    }
    .wm-holder canvas{
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
        background: linear-gradient(45deg, #121617, #0D282B) !important;
    }

    .wm-img{
        display: none;
    }
</style>