<template>
    <div class="team-item">
        <img class="team-image" :src="member.image" />
        <div class="team-content">
            <div class="custom-shape-divider-bottom-1632736404">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                    preserveAspectRatio="none">
                    <path d="M0,0V7.23C0,65.52,268.63,112.77,600,112.77S1200,65.52,1200,7.23V0Z" class="shape-fill">
                    </path>
                </svg>
            </div>
            <div class="team-content-title">
                <span>{{member.name}}</span>
                <span
                    style="font-size: 12px; font-weight: 500; color: var(--color-accent); letter-spacing: 0.12em; margin-top: 4px">{{member.position}}</span>
            </div>

            <div class="team-content-inner">
                <span style="font-size: 14px; color: rgba(255,255,255,0.75)" v-if="member.quote">
                    <span style="font-size: 20px; color: var(--color-accent);">“</span>
                    {{member.quote}}
                    <span style="font-size: 20px; color: var(--color-accent);">”</span>
                </span>

                <a class="team-btn" :href="member.linkedIn" target="_blank">
                    <i class="fab fa-linkedin"></i>
                    LinkedIn
                </a>

                <a class="team-btn" :href="member.insta" target="_blank">
                    <i class="fab fa-instagram"></i>
                    Instagram
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TeamCard",
        props: ["member"]
    }
</script>

<style>
    .team-item {
        display: block;
        width: 320px;
        height: 360px;
        background: var(--color-primary-dark);
        margin: 8px 4px;
        overflow: hidden;
        flex-grow: 1;
        box-shadow: 0px 2.8px 2.2px rgb(165 240 211 / 1%), 0px 6.7px 5.3px rgb(165 240 211 / 1%), 0px 12.5px 10px rgb(165 240 211 / 1%), 0px 22.3px 17.9px rgb(165 240 211 / 1%), 0px 41.8px 33.4px rgb(165 240 211 / 1%), 0px 100px 80px rgb(165 240 211 / 2%);
    }

    .team-image {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .team-content {
        width: 100%;
        height: 292px;
        margin-top: -84px;
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        transition: margin 0.25s ease;
    }

    .team-content-title {
        min-height: 72px;
        padding: 16px 24px;
        color: #FFF;
        font-weight: 600;
        letter-spacing: 0.065em;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: var(--color-primary-dark);
        text-transform: uppercase;
    }

    .team-content-title span {
        margin-top: -22px;
    }

    .team-content-inner {
        flex-grow: 1;
        background: var(--color-primary-dark);
        padding: 4px 24px;
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
    }

    .team-item:hover .team-content {
        margin-top: calc(-72px - 220px);
    }

    .team-btn {
        width: 100%;
        padding: 12px 24px;
        background: var(--color-accent);
        text-transform: none;
        color: var(--color-primary-dark);
        font-weight: 700;
        font-size: 14px;
        border-radius: 8px;
        margin-bottom: 8px;
        justify-content: center;
        display: flex;
        transition: all 0.25s;
        cursor: pointer;
        text-decoration: none;
    }

    .team-btn:first-of-type{
        margin-top: auto;
    }

    .team-btn::before {
        content: ' ';
        width: 0px;
        height: 100%;
        background: var(--color-primary-dark);
        margin-right: 0px;
        transition: all 0.25s;
    }

    .team-btn:hover::before {
        margin-right: 16px;
        width: 2px;
    }

    .team-btn:active::before {
        margin-right: 4px;
        width: 2px;
    }

    .team-btn i{
        font-size: 16px;
        padding-right: 16px;
    }

    .custom-shape-divider-bottom-1632736404 {
        width: 100%;
        overflow: hidden;
        line-height: 0;
        transform: rotate(180deg);
        margin-bottom: -2px;
    }

    .custom-shape-divider-bottom-1632736404 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 24px;
    }

    .custom-shape-divider-bottom-1632736404 .shape-fill {
        fill: var(--color-primary-dark);
    }

    @media screen and (min-width: 640px) {
        .team-item{
            max-width: calc(50% - 8px);
        }
    }

    @media screen and (min-width: 1180px) {
        .team-item{
            max-width: calc(33.33333% - 8px);
        }
    }
</style>